(function(){
	'use strict';

	angular.module('common')
		.directive('searchInput', [function () {
			return {
				restrict: 'A',
				scope: {
					ngModel: "=",
                    afterClear: '&'
				},
				templateUrl: '/static/templates/directive/search-input.html',
				controller: function ($scope) {
				    angular.extend($scope, {
                        clear: clear
                    });

				    function clear() {
				        $scope.ngModel = undefined;
				        if ($scope.afterClear) {
				            $scope.afterClear();
                        }
                    }
				}
			};
		}]);
})();
